const axios = async() => {
    const { default: axios } = await import(/* webpackChunkName: "axios" */ 'axios');

    return axios;
};

import Dialog from './modules/Dialog';

const CLASS_NAME_SHOW = 'show';
const HEADER_SCROLL_OFFSET = 50;
const DIALOG_NAV_SCROLL_OFFSET = 50;

const initJs = () => {

    console.log('hi dom 👋');

    const globalDialog = new Dialog('#site-header', true, true);

    const isShown = element => {
        return element.classList.contains(CLASS_NAME_SHOW);
    }

    const isScrolled = (scrollEl, scrollOffset) => {
        return (scrollEl.scrollTop > scrollOffset);
    }

    const handleAriaAndCollapsedClass = (toggler, collapse, isOpen) => {
        if (isOpen) {
            toggler.setAttribute('aria-expanded', false);
            collapse.classList.remove(CLASS_NAME_SHOW);
        } else {
            toggler.setAttribute('aria-expanded', true);
            collapse.classList.add(CLASS_NAME_SHOW);
        }
    }

    const handleScrollClass = (scroll, element, isScrolled) => {
        if (isScrolled) {
            element.classList.add('scrolled');
        } else {
            element.classList.remove('scrolled');
        }
    }

    const siteHeaderHandler = element => {
        handleScrollClass(document.documentElement, element, isScrolled(document.documentElement, HEADER_SCROLL_OFFSET));
    };

    // Reset Classes
    document.getElementsByTagName('body')[0].classList.remove('open');
    siteHeaderHandler(document.querySelector('#site-header'));

    // Scroll Listeners
    window.addEventListener('scroll', () => {
        siteHeaderHandler(document.querySelector('#site-header'));
    });

    const dialogScrollEl = document.querySelector('.global-dialog--scroll');
    dialogScrollEl.addEventListener('scroll', () => {
        handleScrollClass(
            dialogScrollEl, document.querySelector('.global-dialog'), isScrolled(dialogScrollEl, HEADER_SCROLL_OFFSET));
    });

    // define disclosure
    const disclosures = document.querySelectorAll('.disclosure__toggle');
    disclosures.forEach((disclosure) => {
        disclosure.addEventListener('click', (e) => {
            const el = e.target;
            const siblingEl = el.nextElementSibling;
            handleAriaAndCollapsedClass(el, siblingEl, isShown(siblingEl));
        });
    });

    // define call-to-action dialogs
    const callToActionDialogs = document.querySelectorAll('.block-callToActionCallout .cta-dialog');
    const globalContactCallOutEl = document.querySelector('.cta-dialog.cta-callout--global-contact');

    if (callToActionDialogs.length > 0) {
        [...callToActionDialogs].forEach((ctaDialog) => {
            let dialogId = ctaDialog.getAttribute('id');

            let callToActionDialog = new Dialog(
                `#${dialogId}`
            );

        });
    }

    if (globalContactCallOutEl) {
        const globalContactDialogId = globalContactCallOutEl.getAttribute('id');
        const globalContactDialog = new Dialog(`#${globalContactDialogId}`);
    }

    // dynamically import lazyframe
    let lazyframe = document.querySelector('.lazyframe');
    if (lazyframe) {
        import('./utils/lazyframe-wrapper.js');
    }

    // Reset Form CSRF Tokens
    const formieForms = document.querySelectorAll('form');
    if (formieForms.length > 0) {
        axios().then( request => {
                formieForms.forEach(form => {
                    let csrfInput = form.querySelector('input[name="CRAFT_CSRF_TOKEN"]');
                    let formHandleInput = form.querySelector('input[name="handle"]');
                    let formHandle = formHandleInput.value;
                    request.get(`/actions/formie/forms/refresh-tokens?form=${formHandle}`)
                        .then(result => { return result.data })
                        .then(result => {
                            csrfInput.outerHTML = result.csrf.input;
                            // JavaScript captcha
                            if (result.captchas && result.captchas.javascript) {
                                let jsCaptcha = result.captchas.javascript;
                                form.querySelector('input[name="' + jsCaptcha.sessionKey + '"]').value = jsCaptcha.value;
                            }
                            // Find the Duplicate captcha hidden input, so we can update it
                            if (result.captchas && result.captchas.duplicate) {
                                // Duplicate captcha
                                let duplicateCaptcha = result.captchas.duplicate;
                                form.querySelector('input[name="' + duplicateCaptcha.sessionKey + '"]').value = duplicateCaptcha.value;
                            }
                            if (form.form && form.form.formTheme) {
                                form.form.formTheme.updateFormHash();
                            }
                        })
                        .catch(error => console.error(error));
                });
            })
            .catch((error) => {
                console.log(`Unable to load axios: ${error}`);
            });
    }

}

document.addEventListener('DOMContentLoaded', () => {

    initJs();

    // barba.init({
    //     debug: true,
    //     transitions: [{
    //         once: () => {
    //             initJs();
    //         },
    //         before: () => {
    //             console.log('add class is animating');
    //             document.getElementsByTagName('html')[0].classList.add('is-transitioning');
    //         },
    //         leave: (data) => {
    //             console.log('content leave transition');
    //             return new Promise(resolve => {
    //                 TweenMax.to(data.current.container, .25, {
    //                     opacity: 0,
    //                     onComplete: () => {
    //                         resolve();
    //                     }
    //                 });
    //             });
    //         },
    //         enter: (data) => {
    //             console.log('content enter transition');
    //             window.scrollTo(0, 0);
    //             return new Promise(resolve => {
    //                 TweenMax.from(data.next.container, .25, {
    //                     opacity: 0,
    //                     onComplete: () => {
    //                         resolve();
    //                     }
    //                 });
    //             });
    //         },
    //         after: () => {
    //             console.log('remove class is animating');
    //             document.getElementsByTagName('html')[0].classList.remove('is-transitioning');
    //             // return new Promise(resolve => {
    //             //     initJs();
    //             //     resolve();
    //             // });
    //             initJs();
    //         },
    //     }]
    // });
});

